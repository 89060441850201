// import 'jquery-touchswipe';
import $ from "jquery";
import "magnific-popup";
import Fingerprint2 from "fingerprintjs2";
import { get, post } from "axios";
import toggleMainMenu from "../../blocks/modules/_common/main-menu/main-menu";
import onSwipeMainMenu from "../../blocks/modules/_common/main-menu/swipe-main-menu";
import { renderIdenticon } from "../../blocks/modules/_common/avatar/avatar";
import initMainSlider from "../../blocks/modules/index/main-slider/main-slider";
import { loadCaptcha } from "../../blocks/modules/_common/form/__captcha/form__captcha";
import { openDynamicPopup } from "../../blocks/modules/_common/popup/popup";
import { callLiveTex } from '../../blocks/modules/_common/online-support/online-support';
import { getNavigatorInfo } from "../navg";
import navTabs from "../../blocks/modules/_common/nav-tabs/nav-tabs";
import throttle from "lodash.throttle";
import initBackButton from "../../blocks/modules/_common/back-button/back-button";




if (window.userLogged) {
    toggleMainMenu();
    onSwipeMainMenu();
}

window.openInfoPopup = openDynamicPopup;

$("body .btn_refresh").click(e => {
    e.preventDefault();
    loadCaptcha();
});

function showNoticePanel(text) {
    const message = document.createElement("div");
    message.className = "warning-popup_favourite";
    message.innerHTML = `
      <div class="container-alert">
        <div class="container-alert__text-warning">${text}</div>
        <i class="fa fa-close"></i>
      </div>
      `;
    document.body.appendChild(message);

    let lastScrollTop = 0;

    function noticeMove() {
        const st = $(this).scrollTop();

        if (st > lastScrollTop) {
            $(".warning-popup_favourite").css("bottom", 0);
        } else {
            $(".warning-popup_favourite").css("bottom", 61);
        }

        lastScrollTop = st;
    }

    $(window).scroll(throttle(noticeMove, 250));

    const closeBtn = message.querySelector(".fa-close");
    closeBtn.addEventListener("click", () => {
        localStorage.setItem("showNotice", "false");
        message.remove();
    });
}

// async function getNotice() {
//     try {
//         const { data } = await get('/service/messages/warning');
//         const { message, updateDate } = data.data;
//
//         const savedDate = parseInt(localStorage.getItem('messageDate'), 10);
//         const showNotice = localStorage.getItem('showNotice');
//
//         if (showNotice === 'false' && updateDate === savedDate) {
//             return;
//         }
//
//         showNoticePanel(message);
//         localStorage.setItem('messageDate', updateDate);
//     }
//     catch (e) {
//         console.warn(e);
//     }
// }

// TODO: Change to async after release
function getNotice() {
    get("/service/messages/warning")
        .then(res => res.data)
        .then(data => data.data)
        .then(data => {
            if (!data) {
                return;
            }

            const { message, updateDate } = data;
            const savedDate = parseInt(localStorage.getItem("messageDate"), 10);
            const showNotice = localStorage.getItem("showNotice");

            if (showNotice === "false" && updateDate === savedDate) {
                return;
            }

            showNoticePanel(message);
            localStorage.setItem("messageDate", updateDate);
            localStorage.setItem("showNotice", "true");
        })
        .catch(err => console.warn(err));
}

/**
 * Fingerprint
 */
function fingerprint() {
    try {
        const options = {
            excludeAdBlock: true,
            excludeScreenResolution: true,
            excludeAvailableScreenResolution: true,
            excludePlugins: true
        };

        const navg = getNavigatorInfo();

        const fp = new Fingerprint2(options);
        fp.get(function(code) {
            const img = document.createElement("img");
            img.src = `/fingerprint?code=${code}&amp;nocache=${new Date().getTime()}`;
            img.classList.add("fingerprint-img");
            document.body.appendChild(img);
        });

        const fp2 = new Fingerprint2(options);
        fp2.get(code =>
            post("/service/qstat", {
                fp: code,
                navg,
                uri: window.location.href
            })
        );
    } catch (e) {
        console.warn("Fingerprint blocked!");
    }
}

(function afterPageLoad() {
    window.addEventListener('load', () => {
        if (!window.isMobileClient) {
            callLiveTex();
        }
    });
}());

(function deletePreloader() {
    window.addEventListener("load", () => {
        if (document.getElementById("init")) {
            document.body.removeChild(document.getElementById("init"));
            document.head.removeChild(document.head.querySelector("style"));
        }

        if (location.href === "/" && document.querySelector(".main-slider")) {
            initMainSlider();
        }
    });

})();

fingerprint();
navTabs();
renderIdenticon();
getNotice();
initBackButton();
