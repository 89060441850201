import 'slick-carousel';

export default function initMainSlider() {
	$('.main-slider').slick({
        responsive: true,
        adaptiveHeight: true,
        dots: true,
        prevArrow: '<i class="fa fa-angle-left"></i>',
        nextArrow: '<i class="fa fa-angle-right"></i>',
    });
}