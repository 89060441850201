export default function onSwipeMainMenu() {
  const mainMenuOverlay = document.querySelector('#main-menu-overlay');
  const mainContent = document.querySelector('#main-content');
  const mainMenu = document.querySelector('.main-menu');

  let initialX = null;
  let initialY = null;

  let endX = null;
  let endY = null;

  if (!mainMenu) {
    return;
  }

  const hideMainMenu = () => {
    mainMenu.classList.remove('main-menu_active');
    mainContent.classList.remove('main-content_close');
    mainMenuOverlay.classList.remove('main-menu__overlay_active');
  };

  const startTouch = (e) => {
    initialX = e.changedTouches[0].clientX;
    initialY = e.changedTouches[0].clientY;
  };

  const endTouch = (e) => {
    e.preventDefault();

    endX = e.changedTouches[0].clientX;
    endY = e.changedTouches[0].clientY;

    const diffX = initialX - endX;
    const diffY = initialY - endY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 60) {
        hideMainMenu();
      }
    } else if (initialX === endX) {
      hideMainMenu();
    }
  };

  mainMenuOverlay.addEventListener('touchstart', startTouch, false);
  mainMenuOverlay.addEventListener('touchend', endTouch, false);
}
