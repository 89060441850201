import throttle from 'lodash.throttle';

export default function navTabs() {
  let lastScrollTop = 0;
  let height = window.innerHeight;
  let isLandscape = window.orientation === -90 || window.orientation === 90;
  const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 && navigator.userAgent.indexOf('Android') == -1;
  const $navBar = $('.nav-tabs');

  function handleResize() {
      height = window.innerHeight;
      isLandscape = window.orientation === -90 || window.orientation === 90;
  }

  function handleScroll() {
      const st = $(this).scrollTop();
      const additionalScrollTop = isSafari ? 10 : 0;

      if (st > lastScrollTop + additionalScrollTop){
          $navBar.hide();
      }
      else {
          if(isSafari && isLandscape && window.innerHeight !== height) {
              height = window.innerHeight;
              $navBar.css({'top': height - 61, 'bottom': 'initial'});
          }

          if (isSafari && !isLandscape) {
              $navBar.css({'top': 'initial', 'bottom': '0'});
          }

          $navBar.show();
      }

      lastScrollTop = st;
  }

  $(window).resize(throttle(handleResize, 100));
  $(window).scroll(throttle(handleScroll, 250));

  $(document).ready(function(){
    let path = document.location.pathname;
    path = path.match(/\w+-?\w+/);
    $('.nav-tabs__link[href="/' + path + '"]').addClass('active');
  });
}


