import axios from "axios";
import { getResources, getLiveTexId } from "../../../../js/utils";
import { URL_PLAYER } from "../../../../js/constants";
/**
 * Support chat on
 */
function initLivetex() {
    let playerData;

    const makeLivetexConversation = async () => {
        if (userLogged) {
            if (!playerData) {
                playerData = await axios.get(URL_PLAYER);
            }
            const {
                id: altId,
                name: playerName
            } = playerData.data.currentPlayer;

            LiveTex.setConversationAttributes(
                { altId: altId, playerName: playerName || "Игрок" },
                {}
            );
            LiveTex.setVisitorAttributes(
                function(complete) {},
                function(error) {},
                {
                    name: { name: playerName || "Игрок", is_editable: true },
                    contacts: []
                }
            );
        } 
        LiveTex.showWelcomeWindow();
        
    };

    (window["l" + "i" + "ve" + "Te" + "x"] = true),
        (window["live" + "TexI" + "D"] = getLiveTexId(window.siteCloneId)),
        (window["l" + "iveT" + "ex" + "_o" + "bject"] = true);

    window.LiveTex = {
        onLiveTexReady: function() {
            //LiveTex.hideLabel();
            jQuery("body").on("click", ".button_chat_livetex", function() {
                makeLivetexConversation();
            });
        }
    };
}

export function callLiveTex() {
    initLivetex();
    var t = document["cre" + "ateEl" + "emen" + "t"]("script");
    t.type = "text/javascript";
    t.async = true;
    t.src = "//c" + "s15.livet" + "ex.ru/js" + "/clie" + "nt.j" + "s";
    var c = document["g" + "etElement" + "sByTagN" + "am" + "e"]("script")[0];
    if (c)
        c["par" + "e" + "ntN" + "od" + "e"]["inse" + "rtBe" + "for" + "e"](
            t,
            c
        );
    else
        document["docu" + "mentEle" + "m" + "ent"]["fir" + "stC" + "hild"][
            "a" + "p" + "pe" + "ndCh" + "i" + "l" + "d"
        ](t);
}

//TODO remove load function after testing chat on production (if change link in resources not needed)
//make chat list static
async function loadSocialChats() {
    try {
        const resources = await getResources("onlineChat");
        if (resources) {
            var blockedSocials = ["vk", "odnoklassniki"];
            $.each(resources.data, function(i, item) {
                if (i.indexOf(".link") >= 0) {
                    //TODO filter json
                    var network = i.split(".");
                    var prefix = network.length > 0 ? network[0] : false;
                    if (!showAllSocial && blockedSocials.includes(prefix)) {
                        return;
                    }
                    if (prefix) {
                        if (resources[`${prefix}.name`]) {
                            //resources[`${prefix}.name`]
                        }
                        $(".js-chat-list")
                            .append(`<a href="${item}" class="icon-colored ${prefix}">
                                <i class="fa fa-${prefix}"></i>
                            </a>`);
                    }
                }
            });
        }
    } catch (error) {
        console.log(error);
    }
}

loadSocialChats();
