import { getResources } from '../../../../js/utils';

export default function toggleMainMenu() {
  const menuBtn = document.querySelector('#open-mnu');
  const closeBtn = document.querySelector('#close-mnu');
  const mainMenu = document.querySelector('.main-menu');
  const mainContent = document.querySelector('#main-content');
  const mainMenuOverlay = document.querySelector('#main-menu-overlay');

  if (mainMenu) {
    menuBtn.addEventListener('click', (e) => {
      e.preventDefault();
      mainMenu.classList.add('main-menu_active');
      mainContent.classList.add('main-content_close');
      mainMenuOverlay.classList.add('main-menu__overlay_active');
    });
  }

  if (closeBtn) {
    closeBtn.addEventListener('click', () => {
      mainMenu.classList.remove('main-menu_active');
      mainContent.classList.remove('main-content_close');
      mainMenuOverlay.classList.remove('main-menu__overlay_active');
    });
  }
  if (mainMenuOverlay) {
    mainMenuOverlay.addEventListener('click', () => {
      mainMenu.classList.remove('main-menu_active');
      mainContent.classList.remove('main-content_close');
      mainMenuOverlay.classList.remove('main-menu__overlay_active');
    });
  }
}

async function loadSocials() {
  try {
    const resources = await getResources('social');
    if (resources) {
      $('.js-social-links').append(`<p class="main-menu__text">Подпишитесь на нас:</p>`);
      var blockedSocials = ['vk', 'odnoklassniki'];
      $.each(resources, function (i, item) {
        if (i.indexOf('.link') >= 0) { //TODO filter json
          var network = i.split('.');
          var prefix = network.length > 0 ? network[0] : false;
          if (!showAllSocial && blockedSocials.includes(prefix)) {
            return;
          }
          if (prefix) {
            if (resources[`${prefix}.name`]) {
              //resources[`${prefix}.name`]
            }
            $('.js-social-links').append(`<a href="${item}" target="_blank" class="icon-colored ${prefix}"><i class="fa fa-${prefix}"></i></a>`);
          }
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
}

loadSocials();
